export const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['title', 'date'],
    properties: {
      name: {
        title: '單位',
        type: 'string',
      },
      action: {
        title: '動作',
        type: 'string',
        enum: ['加入', '退出'],
        default: '加入',
      },
      reason: {
        title: '原因',
        type: 'string',
      },
    },
  },
  uiSchema: {
    reason: {
      'ui:widget': 'textarea',
    },
  },
};
